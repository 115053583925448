import {ComponentType} from "react";
import {get, isNumber, reduce} from "lodash";
import {captureMessage} from "@sentry/react";
// import {SALARY_CAP_DELIMITER} from "data/constants";
import {AxiosError} from "axios";
import {IAxiosApiError, IAxiosErrorMessageObject} from "data/types/global";

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

export const trackSentryErrors = (
	exception: unknown,
	context: Record<string, unknown>,
	contextName: string = "unknown error"
) => {
	const errorName = get(exception, "message", get(exception, "text", contextName));

	captureMessage(errorName, (scope) => {
		scope.setContext(
			contextName,
			reduce(
				context,
				(acc, value, key) => {
					if (context[key] !== undefined) {
						acc[key] = JSON.stringify(context[key]);
					}

					return acc;
				},
				{} as Record<string, unknown>
			)
		);

		scope.setTag("handler", contextName);
		scope.setTransactionName(contextName);

		return scope;
	});
};

export function getClassAccordingStatement(className: string, statement: boolean) {
	return statement ? className : "";
}

export function getDashedValue<T>(value: T | undefined | null, replacer = "-"): string {
	if (value === null || value === undefined) {
		return replacer;
	}
	return String(value);
}

export function getNonUndefinedValue<T>(value: T | undefined | null) {
	if (value === undefined || value === null) {
		return 0;
	}
	return value;
}

export const getPreparedDriverCost = (number?: number) => {
	const numericNumber = Number(number);
	if (!isNumber(numericNumber)) {
		return "";
	}

	if (numericNumber === 0) {
		return 0;
	}

	const million = 10 ** 6;
	const thousand = 10 ** 3;

	const abs = Math.abs(numericNumber);

	// const is_number_thousand = (abs < million) && (abs >= thousand),
	const is_number_thousand = abs < million;
	const is_number_million = abs >= million;
	if (is_number_thousand) {
		return `${(abs / thousand).toFixed(0)}k`;
	} else if (is_number_million) {
		return `${(numericNumber / million).toFixed(3)}m`;
	}
};

export function getErrorMessageFromAxiosResponse(event: AxiosError<IAxiosApiError, unknown>) {
	if (!event) {
		return "";
	}

	if (!event.response?.data?.errors) {
		return event.message;
	}

	return Object.values(event.response?.data?.errors)
		.map((e: IAxiosErrorMessageObject) => e.message)
		.join(" \n");
}
