import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import {RequestState} from "data/enums";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import React from "react";
import {AxiosError} from "axios";
import {getErrorMessageFromAxiosResponse, trackSentryErrors} from "data/utils";
import type {IAxiosApiError} from "data/types/global";

export interface IForm extends HTMLFormElement {
	email: HTMLInputElement;
}

export interface IOffSeasonController extends ViewController {
	get isPrivateLocked(): boolean;
	get error(): string | undefined;
	get formErrors(): Record<string, string>;
	get isFormLocked(): boolean;
	get formValidationHelper(): IFormValidationHelper;
	get requestState(): RequestState;
	get email(): string;

	handleSubmitForm: (event: React.SyntheticEvent<IForm>) => void;
	handleFormChange: (event: React.SyntheticEvent<IForm>) => void;
}

@injectable()
export class OffSeasonController implements IOffSeasonController {
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _error?: string = undefined;
	@observable private _email = "";

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper
	) {
		makeAutoObservable(this);
	}

	get isPrivateLocked(): boolean {
		return this._userStore.isPrivateLocked;
	}

	get error() {
		return this._error;
	}

	get requestState() {
		return this._requestState;
	}

	get formErrors() {
		return this._validationHelper.formErrors;
	}

	get isFormLocked() {
		return this._requestState === RequestState.PENDING;
	}

	get formValidationHelper(): IFormValidationHelper {
		return this._validationHelper;
	}

	get email() {
		return this._email;
	}

	@action handleFormChange = (event: React.SyntheticEvent<IForm>) => {
		this._error = undefined;
		this._requestState = RequestState.IDLE;
		const form = event.currentTarget;

		this._email = form.email.value;
	};

	@action private onError = (error: AxiosError) => {
		trackSentryErrors(error, {}, "form login");
		this._requestState = RequestState.ERROR;
		this._error = getErrorMessageFromAxiosResponse(
			error as AxiosError<IAxiosApiError, unknown>
		);
	};

	@action handleSubmitForm = (event: React.SyntheticEvent<IForm>) => {
		event.preventDefault();
		this._error = undefined;
		const form = event.currentTarget;

		if (!this._validationHelper.checkValidity(form)) {
			return;
		}

		const email = form.email.value;

		this._requestState = RequestState.PENDING;

		void this._userStore.preregister({email}).then(this.onSuccess).catch(this.onError);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
		this._email = "";
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
