import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {
	IAuthApiProvider,
	ICheckUserNamePayload,
	IForgotPasswordPayload,
	ILoginPayload,
} from "data/providers/api/auth.api.provider";
import {Bindings} from "data/constants/bindings";
import {
	IAxiosApiError,
	ICustomFieldFragment,
	IUserCustomFieldValueFragment,
} from "data/types/global";
import {get} from "lodash";
import {getErrorMessageFromAxiosResponse, trackSentryErrors} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";
import {IS_PRESEASON, PRESEASON_STORAGE_KEY} from "data/constants";
import {ModalType} from "data/enums";

export interface IUser {
	id: number;
	email?: string;
	teamName: string;
	firstName: string;
	lastName: string;
	country: string;
	isNotificationsEnabled: boolean;
	optIn: boolean;
	userName?: string;
	recovered: boolean;
}

export interface IChangePasswordPayload {
	password: string;
}

export interface IRecoverUserPayload {
	isDriver: boolean;
	phone: string | null | undefined;
}

export interface IResetPasswordPayload {
	token: string;
	password: string;
}

export interface IGeneralTempUserData {
	email?: string;
	password?: string;
	firstName?: string;
	lastName?: string;
	teamName?: string;
	country?: string;
	optIn?: boolean;
	isNotificationsEnabled?: boolean;
	dob?: string;
	favDriver?: string;
	postcode?: number;
}

export interface ITempUserData extends IGeneralTempUserData {
	customFields?: IUserCustomFieldValueFragment[];

	[name: string]: unknown;
}

export interface IRegistrationPayload {
	email?: string;
	password?: string;
	firstName?: string;
	lastName?: string;
	teamName?: string;
	country?: string;
	phone?: string | null;
	terms?: boolean;
	notifications?: boolean;
	optIn?: boolean;
	dob?: string;
	favDriver?: string;
	postcode?: number;
	isDriver?: boolean;
}

export interface IUpdateUserPayload extends IRegistrationPayload {
	confirmPassword?: string;
}

export interface IPreRegoPayload {
	email: string;
}

export interface IUserStore {
	get user(): IUser | undefined;

	get fullName(): string;

	get isAuthorized(): boolean;

	get wasLoggedOut(): boolean;

	get teamName(): string | undefined;

	get registrationCustomFields(): ICustomFieldFragment[] | null;

	get tmpUserData(): ITempUserData | null;

	get isSessionChecked(): boolean;

	get isPrivateLocked(): boolean;

	login(payload: ILoginPayload): Promise<void>;

	preregister(payload: IPreRegoPayload): Promise<void>;

	logout(): Promise<void>;

	checkUsername(payload: ICheckUserNamePayload): Promise<string | false>;

	setTmpUserData(data: ITempUserData): void;

	register(payload: IRegistrationPayload): Promise<IUser | void>;

	forgotPassword(payload: IForgotPasswordPayload): Promise<boolean>;

	resetPassword(payload: IResetPasswordPayload): Promise<boolean>;

	update(payload: IUpdateUserPayload): Promise<IUser | void>;

	deactivate(): Promise<void>;

	changePassword(payload: IChangePasswordPayload): Promise<IUser | void>;

	restoreSession(): Promise<void>;

	recoverUser(payload: IRecoverUserPayload): Promise<void>;
}

@injectable()
export class UserStore implements IUserStore {
	@observable private _checkUserNameRequest?: ReturnType<IAuthApiProvider["checkUsername"]>;

	constructor(
		@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider,
		@inject(Bindings.ModalsStore) private _modalStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _user?: IUser = undefined;

	get user() {
		return this._user;
	}

	@observable private _isAuthorized = false;

	get isAuthorized(): boolean {
		return this._isAuthorized;
	}

	@observable private _wasLoggedOut = false;

	get wasLoggedOut(): boolean {
		return this._wasLoggedOut;
	}

	@observable private _tmpUserData: ITempUserData | null = null;

	get tmpUserData(): ITempUserData | null {
		return this._tmpUserData;
	}

	@observable private _isSessionChecked = false;

	get isSessionChecked() {
		return this._isSessionChecked;
	}

	@computed get fullName() {
		if (!this._user) {
			return "";
		}

		const {firstName, lastName} = this._user;
		return `${firstName} ${lastName}`;
	}

	get teamName(): string | undefined {
		return this._user?.teamName;
	}

	@observable private _registrationCustomFields: ICustomFieldFragment[] | null = null;

	get registrationCustomFields() {
		return this._registrationCustomFields;
	}

	get isPrivateLocked(): boolean {
		return IS_PRESEASON && localStorage.getItem(PRESEASON_STORAGE_KEY as string) !== "true";
	}

	async checkUsername(variables: ICheckUserNamePayload) {
		this._checkUserNameRequest = this._authApi["checkUsername"](variables);

		return this._checkUserNameRequest
			.then((result) => {
				return get(result.data, "errors[0].message", false) as string | false;
			})
			.catch((event: AxiosError<IAxiosApiError, unknown>) => {
				return getErrorMessageFromAxiosResponse(event) || false;
			});
	}

	@action setTmpUserData(data: ITempUserData) {
		const tmpData = this._tmpUserData || {};

		this._tmpUserData = {
			...tmpData,
			...data,
		};
	}

	@action
	async login(payload: ILoginPayload): Promise<void> {
		const response = await this._authApi.login(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._isAuthorized = true;
			this._wasLoggedOut = false;
		});
	}

	@action async preregister(payload: IPreRegoPayload) {
		await this._authApi.preregister(payload);
	}

	async forgotPassword(payload: IForgotPasswordPayload): Promise<boolean> {
		const response = await this._authApi.forgotPassword(payload);
		const errorObj = get(response.data, "errors[0]") as {message: string};
		const isError = get(errorObj, "message");
		return Boolean(!isError);
	}

	async resetPassword(payload: IResetPasswordPayload): Promise<boolean> {
		const response = await this._authApi.resetPassword(payload);
		const errorObj = get(response.data, "errors[0]") as {message: string};
		const isError = get(errorObj, "message");
		return Boolean(!isError);
	}

	@action clearUsedData = () => {
		this._user = undefined;

		return this._authApi.logout();
	};

	@action register({
		email,
		password,
		firstName,
		lastName,
		teamName,
		country,
		notifications,
		terms,
		dob,
		phone,
		isDriver,
		favDriver,
		postcode,
	}: IRegistrationPayload): Promise<IUser | void> {
		return this._authApi
			.signUp({
				email,
				password,
				firstName,
				lastName,
				teamName,
				country: country || this.tmpUserData?.country,
				notifications,
				phone,
				optIn: notifications,
				isDriver,
				terms,
				dob,
				favDriver,
				postcode,
			})
			.then((value) => {
				return value.data.success.user;
			})
			.then(this.storeUserOnSuccessPlatformAuth)
			.then(this.clearTmpValues)
			.catch(this.onAuthError);
	}

	@action update(payload: IUpdateUserPayload): Promise<IUser | void> {
		return this._authApi
			.update(payload)
			.then((value) => {
				return value.data.success.user;
			})
			.then(this.storeUserOnSuccessPlatformAuth)
			.then(this.clearTmpValues)
			.catch(this.onAuthError);
	}

	@action changePassword(payload: IChangePasswordPayload): Promise<IUser | void> {
		return this._authApi
			.changePassword(payload)
			.then((value) => {
				return value.data.success.user;
			})
			.then(this.storeUserOnSuccessPlatformAuth)
			.then(this.clearTmpValues)
			.catch(this.onAuthError);
	}

	@action restoreSession() {
		return this._authApi
			.getCurrentUser()
			.then((value) => {
				const user = value.data.success.user;
				this.storeUserOnSuccessPlatformAuth(user);
			})
			.catch((error: AxiosError) => {
				// 401 is expected error for logged out users
				if (error?.response?.status !== 401) {
					trackSentryErrors(error, {}, "restoreSession");
				}
				runInAction(() => {
					this._isSessionChecked = true;
				});
			})
			.finally(() =>
				runInAction(() => {
					this._isSessionChecked = true;
				})
			);
	}

	@action
	public recoverUser(payload: IRecoverUserPayload) {
		return this._authApi
			.recoverUser(payload)
			.then((value) => {
				const user = value.data.success.user;
				this.storeUserOnSuccessPlatformAuth(user);
			})
			.catch(this.onAuthError);
	}

	@action
	async logout() {
		await this._authApi.logout();

		runInAction(() => {
			this._user = undefined;
			this._isAuthorized = false;
			this._wasLoggedOut = true;
		});
	}

	@action
	async deactivate() {
		await this._authApi.deactivate();
		runInAction(() => {
			this._user = undefined;
			this._isAuthorized = false;
			this._wasLoggedOut = true;
		});
	}

	@action private clearTmpValues = <T>(args: T) => {
		this._wasLoggedOut = false;
		this._tmpUserData = null;

		return args;
	};

	private onAuthError = async (error: AxiosError) => {
		trackSentryErrors(error, {}, "onAuthError");

		if (error.response?.status === 418) {
			this._modalStore.showModal(ModalType.USER_EXISTS);
			throw error;
		}

		if (error.response?.status !== 401) {
			throw error;
		}

		await this.clearUsedData();
		window.location.reload();
		runInAction(() => {
			this._isSessionChecked = false;
		});

		throw error;
	};

	@action private storeUserOnSuccessPlatformAuth = (user: IUser): IUser => {
		this._user = user;
		this._isAuthorized = true;
		return user;
	};
}
